import { FC, memo, ReactNode } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import Page from '../Page';

interface RouteWithLayoutProps extends RouteProps {
  children?: ReactNode;
  pageClassName?: string;
  hideNavbar?: boolean;
  title?: string;
}

const RouteWithLayout: FC<RouteWithLayoutProps> = memo(
  ({ children, pageClassName, hideNavbar = false, title, ...rest }) => (
    <Route {...rest}>
      <Page className={pageClassName} hideNavbar={hideNavbar} title={title}>
        {children}
      </Page>
    </Route>
  ),
);

export default RouteWithLayout;
