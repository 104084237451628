import { theme } from 'antd';

export const appTheme = {
  components: {
    Avatar: {
      colorTextPlaceholder: '#BFBFBF', // was rgba(0, 0, 0, .25)
    },
    Dropdown: {},
    Select: {},
    Table: {},
  },
  cssVar: { key: 'ant' },
  extractStyle: true,
  // add variables to css like --antd-color-primary
  hashed: true,
  token: {
    borderRadius: 2,
    colorLink: '#459102',
    colorPrimary: '#459102',
    colorPrimaryBg: '#f5ffec',
    fontFamily: 'Inter, sans-serif',
    motionUnit: 0.1,
  },
};

// Override default theme
Object.keys(appTheme.token).forEach((key) => {
  const defaultSeed = theme.defaultSeed as any;
  const token = appTheme.token as any;
  if (defaultSeed[key]) {
    defaultSeed[key] = token[key];
  }
});

export const entromyHomeGaugeChartColors = [
  '#135200',
  '#237804',
  '#389E0D', // has copy in this file
  '#52C41A', // has copy in this file
  '#73D13D',
  '#95DE64',
];
export const entromyHomeQuartileColors = {
  fourth: {
    backgroundColor: '#FFF2E8',
    color: '#FA541C',
  },
  second: {
    backgroundColor: '#F9FAFC',
    color: '#76767B',
  },
  third: {
    backgroundColor: '#FFFBE6', // warning-bg
    color: '#FAAD14', // warning-color
  },
  top: {
    backgroundColor: '#F6FFED',
    color: '#52C41A', // has copy in this file
  },
};

export const entromyParticipationRateColor = '#469102';

export const entromyHomeTrendsColors = {
  benchmark: '#4F81BD',
  enps: '#CC9833',
  participationAndOhb: '#389E0D', // has copy in this file
};

export const entromyReportsCoreCompetencies360Colors = {
  devAreas: '#c37577',
  opportunities: '#f2ad68',
  strengths: '#94c346',
};

export const entromyReportsScoresOverviewBenchmarkChartColors = [
  '#c3d69b',
  '#c4d69b',
  '#4f6228',
  '#9bbb59',
  '#8bc34a',
  '#cddc39',
];

export const entromyReportsScoresOverviewBenchmarkChartColorBg = '#264161';
export const entromyReportsScoresOverviewBenchmarkChartColorNanBg = '#5f4b7b';

export const entromyReportsOnaCollaborationAndOutreachBetweenGroupsChartColors = [
  '#6f8db9',
  '#dfe5ef',
  '#34445F',
];

export const entromyReportsOnaBetweenInfluencersColors = [
  '#5470C6',
  '#91CD75',
  '#FAC858',
  '#EE6667',
  '#72C0DE',
  '#3BA272',
  '#FC8453',
  '#9A60B4',
  '#EA7CCC',
  '#808080',
];

export const entromyONABottleneck = '#999999';

export const entromyReportsOrganisationalMaturityBubbleBg = '#4572A7';
export const entromyReportsOverallGraphsColors = [
  '#7293cb',
  '#e1974c',
  '#84ba5b',
  '#d35e60',
  '#808585',
  '#9067a7',
  '#ab6857',
  '#ccc210',
  '#396ab1',
  '#d67c30',
  '#3e9651',
];

export const entromyReportsNPSColors = [
  '#d9a7a9', // closest token.colorErrorBorder
  '#dddddd', // closest token.colorTextQuaternary
  '#cce2a6', // same as $entromy-reports-promoters
];

export const entromyKeyFindingsColors = {
  // idea: token.colorInfoHover
  challenges: 'rgb(217, 167, 169)',
  positives: 'rgb(204, 226, 166)', // idea: token.colorErrorBorder
  recs: '#dcecf6', // token.colorBgTextActive
};

export const entromyMapParticipationBarColors = {
  green: 'rgb(105, 145, 220)',
  lightGreen: 'rgb(242, 242, 242)',
  lightRed: 'rgb(225, 231, 236)',
  red: 'rgb(242, 242, 242)',
};

export const entromyHeatmapDefaultColors = {
  green: 'rgb(152, 196, 73)',
  lightGreen: 'rgb(240, 246, 229)',
  lightRed: 'rgb(251, 245, 246)',
  red: 'rgb(167, 52, 55)',
};
