import { useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { UserContext } from '../../store/user';
import { getRedirectUrl, UserUI } from '../../utils/auth';
import Loader from '../Loader';

const RootRedirectRoute = () => {
  const { user } = useContext(UserContext);
  return !user.userId ? <Loader /> : <Redirect to={getRedirectUrl(user as UserUI)} />;
};

export default RootRedirectRoute;
