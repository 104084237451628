import { useEffect, useState } from 'react';
import { faListAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Button, Col } from 'antd';
import classNames from 'classnames';
import moment from 'moment';

import { DEFAULT_API_FORMAT } from '../../constants';
import { get } from '../../utils/backend';
import ManageModalJS from '../Modals/ManageSurveys';

const ManageModal = ManageModalJS as any;

interface SurveyRes {
  id: number;
  surveyname: string;
  organization: any | null;
  createdBy: any | null;
  created_at: number;
  updated_at: number;
  userFirstTimeSurvey: boolean;
  viewGroups: any | null;
  surveyGroupViewers: any | null;
  recipients: number;
  participants: number;
  locales: any | null;
  last_run: string | null; // '2024-04-12';
  last_close: string | null; // '2024-08-14';
  lastResponse: any | null;
  provideSuggestions: any | null;
  allowRanking: any | null;
  rankedStatements: any | null;
  enableSelfRegistration: any | null;
  questionNumber: number;
  participate: boolean;
  view: any | null;
  surveyInstanceId: number | null;
  reviewStatementsCount: number;
  isRunning: boolean;
  token: any | null;
  disableTour: boolean;
  styles: any | null;
  image: any | null;
  type: string; // '360' | 'regular'
  customerClass: any | null;
  viewIndividualReports: boolean;
  components: any | null;
  listSelectItems: any | null;
  surveyProperties: any | null;
  questions: any | null;
  surveyQuestions: any | null;
  surveyVCPQuestions: any | null;
  surveyQuestionIdList: any | null;
  surveyCommentQuestions: any | null;
  commentQuestionsCount: number;
  surveyDimensions: any | null;
  lastSurveyInstance: any | null;
  ownerHasOtherSurveys: number;
  whitelistedOrg: boolean;
  testMessageSubject: any | null;
  testMessageBody: any | null;
  linkToSurveyPreview: any | null;
  selfRegistrationPosition: any | null;
  autodetectLocales: boolean;
  userID360: any | null;
  groupID: any | null;
  groupHierarchy: any | null;
  parentID360: number | null;
  members: any | null;
  managers: any | null;
  user360: any | null;
  emails: any | null;
  nominationsListType: number | null; // 0
  statementGroupDimensionId: number | null; // 0
  deiTemplateType: any | null;
  nominationsDimensions: any | null;
  threshold: any | null;
  managerFeedbackDeadline: any | null;
  parentSurveyName: any | null;
  running: boolean;
}

const ManageSurveys = ({
  setIsManageModalActive,
}: {
  setIsManageModalActive: (status: boolean) => void;
}) => {
  const [showManageModal, setShowManageModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [surveys, setSurveys] = useState<SurveyRes[]>([]);

  async function fetchAndSetSurveys() {
    setLoading(true);
    try {
      const list: SurveyRes[] | undefined = await get('/surveys/manage_survey');
      if (list?.length) {
        setSurveys(
          list.sort(
            (a, b) =>
              moment(b.last_close, DEFAULT_API_FORMAT).valueOf() -
              moment(a.last_close, DEFAULT_API_FORMAT).valueOf(),
          ),
        );
      }
    } finally {
      setLoading(false);
    }
  }

  function onManageModalOpen() {
    setShowManageModal(true);
    fetchAndSetSurveys();
  }

  useEffect(() => {
    setIsManageModalActive(showManageModal);
  }, [showManageModal]);

  useEffect(() => {
    fetchAndSetSurveys();
  }, []);

  return (
    <>
      {!!surveys.length && (
        <Col>
          <Button
            type="text"
            className={classNames('navbar__menu__item', {
              'navbar__menu__item--active': showManageModal,
            })}
            onClick={onManageModalOpen}
          >
            <Icon icon={faListAlt} />
            <span className="navbar__menu__item__badge">{surveys.length}</span>
            <div>Manage</div>
          </Button>
        </Col>
      )}
      <ManageModal
        visible={showManageModal}
        onCancel={() => setShowManageModal(false)}
        surveys={surveys}
        loading={loading}
      />
    </>
  );
};

export default ManageSurveys;
