import { entromyHeatmapDefaultColors } from '../appTheme';

export class Color {
  constructor(public r: number, public g: number, public b: number) {}
}

export const rgbaToColorsObject = (stringColors: {
  [key: string]: string;
}): { [key: string]: Color } =>
  Object.keys(stringColors).reduce(
    (acc, key) => ({ ...acc, [key]: rgbaToColor(stringColors[key]) }),
    {},
  );

const defaultColors = rgbaToColorsObject(entromyHeatmapDefaultColors);

function componentToHex(c: number): string {
  const hex = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
}

function rgbToHex(r: number, g: number, b: number, withHash = false): string {
  return `${withHash ? '#' : ''}${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
}

export default function getHeatmapColor(
  val: number,
  min = 0,
  max = 100,
  colors = defaultColors,
  returnHex = false,
): string {
  function Interpolate(start: number, end: number, steps: number, count: number): number {
    return Math.floor(start + ((end - start) / steps) * count);
  }

  const { green, lightRed, red, lightGreen } = colors;
  let diff = max - min;
  diff = diff || 2;
  const center = (max + min) / 2;

  const [start, end] = val >= center ? [lightGreen, green] : [lightRed, red];

  const value = Math.abs(center - val);
  const r = Interpolate(start.r, end.r, diff / 2, value);
  const g = Interpolate(start.g, end.g, diff / 2, value);
  const b = Interpolate(start.b, end.b, diff / 2, value);

  if (returnHex) {
    return rgbToHex(r, g, b);
  }

  return `rgb(${r},${g},${b})`;
}

export function rgbaToColor(rgba: string): Color {
  const regex =
    /rgba?\(\s*([0-9]+)\s*,\s*([0-9]+)\s*,\s*([0-9]+)\s*(?:,\s*([0-9]*\.?[0-9]+)\s*)?\)/;
  const result = regex.exec(rgba);

  if (!result) {
    throw new Error('Invalid RGBA color string');
  }

  const r = parseInt(result[1], 10);
  const g = parseInt(result[2], 10);
  const b = parseInt(result[3], 10);

  return new Color(r, g, b);
}
