import { FC, memo, ReactNode, useContext } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

import { CommonContext } from '../../store/common';
import { UserContext } from '../../store/user';
import { getUserInfo } from '../../utils/auth';
import Page from '../Page';

interface PrivateRouteProps extends RouteProps {
  children?: ReactNode;
  isAdmin?: boolean;
  isSuperAdmin?: boolean;
  isDiscussionMaterialRole?: boolean;
}

const PrivateRoute: FC<PrivateRouteProps> = memo(
  ({
    children,
    isAdmin = false,
    isSuperAdmin = false,
    isDiscussionMaterialRole = false,
    ...rest
  }) => {
    const { user } = useContext(UserContext);
    const { setReturnUrl } = useContext(CommonContext);
    const { pathname } = useLocation();

    function isAuthenticated(): boolean {
      return !!user.userId || !!getUserInfo().userId;
    }

    function isAllowed(): boolean {
      const superAdminAllowed = isSuperAdmin && (user.isSuperAdmin || getUserInfo().isSuperAdmin);
      const adminAllowed = isAdmin && (user.isAdmin || getUserInfo().isAdmin);
      const discussionMaterialAllowed =
        isDiscussionMaterialRole &&
        (user.isDiscussionMaterialRole || getUserInfo().isDiscussionMaterialRole);

      return (
        superAdminAllowed ||
        adminAllowed ||
        discussionMaterialAllowed ||
        (!isSuperAdmin && !isAdmin && !isDiscussionMaterialRole)
      );
    }

    let redirectTo: string | undefined;

    if (!isAuthenticated()) {
      setReturnUrl(pathname);
      redirectTo = '/login';
    } else if (!isAllowed()) {
      redirectTo = '/not-allowed';
    }

    return (
      <Route
        {...rest}
        render={({ location }) =>
          !redirectTo ? (
            <Page>{children}</Page>
          ) : (
            <Redirect
              to={{
                pathname: redirectTo,
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  },
);

export default PrivateRoute;
