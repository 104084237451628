import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  DashboardOutlined,
  DownOutlined,
  LogoutOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  faChartLine,
  faHome,
  faListAlt,
  faQuestionCircle,
  faSearch,
  faSignOutAlt,
  faTable,
  faUserNinja,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Col, Dropdown, Flex, Row, Select } from 'antd';
import classNames from 'classnames';

import logoDefault from '../../assets/images/Entromy-logo.png';
import { API_URL } from '../../config';
import { SURVEY_TYPE } from '../../constants';
import UserAvatar from '../../pages/UserAccount/userAvatar';
import { CommonContext } from '../../store/common';
import { FiltersContext } from '../../store/filters';
import { UserContext } from '../../store/user';
import { showError, showSuccess, showWarning } from '../../utils/alerts';
import {
  checkPasswordChangeDate,
  createUserFromResponse,
  getRedirectUrl,
  removeUserInfo,
} from '../../utils/auth';
import { get } from '../../utils/backend';
import ManageSurveys from '../ManageSurveys';

import './styles.scss';

const { Option } = Select;

const Navbar = () => {
  const { logo, organizations, organization } = useContext(CommonContext);
  const { user, logout, setUser } = useContext(UserContext);
  const { clearFilters } = useContext(FiltersContext);
  const { isSuperAdmin, isSuperDuperAdmin, isAdmin, isDiscussionMaterialRole, orgId } = user;
  const [surveys, setSurveys] = useState([]);
  const [has360View, setHas360View] = useState(false);
  const [isManageModalActive, setIsManageModalActive] = useState(false);
  const location = useLocation();
  const history = useHistory();

  function isActive(state) {
    return !isManageModalActive && location.pathname.indexOf(state) !== -1;
  }

  function getSelectedSettingsKey() {
    return [
      'users',
      'groups',
      'categories',
      'topics',
      'questions',
      'surveys',
      'settings',
      'portfolioViews',
      'teamViews',
    ].filter((item) => isActive(item));
  }

  function isManagerReviewPage() {
    return location?.pathname?.startsWith('/manager-review/');
  }

  function getSelectedReportKey() {
    return [
      'discussion-materials',
      'reports/portfolio',
      'reports/teamReport',
      'teamViews',
      'reports/dei-reports',
      'user-account',
      'internal/organizations',
      'internal/control-board-cs',
    ].filter((item) => isActive(item));
  }

  function getSelectedVCPKey() {
    return ['plan'].filter((item) => isActive(item));
  }

  function onLogout() {
    logout();
    clearFilters();
    history.push('/login');
  }

  function handlePasswordChangeRedirect(curUser, userInfo) {
    history.push(getRedirectUrl(curUser));
    window.location.pathname = getRedirectUrl(userInfo);
  }

  function switchOrg(id) {
    get(`/organizations/swich_org/${id}`)
      .then(async (resp) => {
        removeUserInfo();
        const userInfo = createUserFromResponse(resp);
        setUser(userInfo);
        const passwordValidation = await checkPasswordChangeDate();
        if (passwordValidation.daysLeft < 1) {
          history.push('/change-password');
          return;
        }
        if (passwordValidation.daysLeft < 5) {
          showWarning(
            `Your password hasn't been changed for approximately ${passwordValidation.daysValidityPeriod} days. For increased security, you have  ${passwordValidation.daysLeft} days to set a new password. Please use the menu at the top: 'Your Name -> Change Password'.`,
            'Change password',
            () => {
              handlePasswordChangeRedirect(user, userInfo);
            },
          );
          return;
        }
        handlePasswordChangeRedirect(user, userInfo);
      })
      .catch((e) => {
        showError(e.message);
      });
  }

  function clearCahce() {
    get('/cache/refresh').then(() => showSuccess('Cache deleted', 'Done'));
  }

  useEffect(async () => {
    if (isAdmin || isDiscussionMaterialRole) {
      try {
        const orgContext = await get(`/common/org_user_context/${orgId}`);
        setSurveys(orgContext.surveys ?? []);
      } catch (e) {
        showError('Failed to load survey list for organization!');
      }
    }
  }, [isAdmin, isDiscussionMaterialRole]);

  useEffect(async () => {
    setHas360View((surveys ?? []).some(({ type }) => type === SURVEY_TYPE.CHILD_360));
  }, [surveys]);

  const survey360Types = [SURVEY_TYPE.PARENT_360, SURVEY_TYPE.CHILD_360];
  const surveyDeiTypes = [SURVEY_TYPE.DEI, SURVEY_TYPE.ESG];

  const hasDeiSurveys = useMemo(
    () => surveys.some(({ type }) => surveyDeiTypes.includes(type)),
    [surveys],
  );

  const hasOnlyDeiSurveys = useMemo(
    () => surveys.every(({ type }) => surveyDeiTypes.includes(type)),
    [surveys],
  );

  const handleMenuClick = (e) => {
    if (e.key === 'settingsClearCache') {
      clearCahce();
    } else if (e.key === 'userLogout') {
      onLogout();
    }
  };

  const settingsMenu = {
    selectable: true,
    selectedKeys: getSelectedSettingsKey(),
    className: 'navbar__submenu',
    onClick: handleMenuClick,
    items: [
      isAdmin
        ? {
            label: <Link to="/configure/users">Users</Link>,
            key: 'users',
          }
        : null,
      isAdmin
        ? {
            type: 'divider',
          }
        : null,
      isAdmin
        ? {
            label: <Link to="/configure/questions">Questions</Link>,
            key: 'questions',
          }
        : null,
      isAdmin
        ? {
            label: <Link to="/configure/surveys">Surveys</Link>,
            key: 'surveys',
          }
        : null,
      isAdmin
        ? {
            label: <Link to="/configure/categories">Categories</Link>,
            key: 'categories',
          }
        : null,
      isAdmin
        ? {
            label: <Link to="/configure/topics">Topics</Link>,
            key: 'topics',
          }
        : null,
      isAdmin
        ? {
            label: <Link to="/configure/portfolioViews">Portfolio Views</Link>,
            key: 'portfolioViews',
          }
        : null,
      isAdmin
        ? {
            type: 'divider',
          }
        : null,
      isAdmin
        ? {
            label: <Link to="/settings">Settings</Link>,
            key: 'settings',
          }
        : null,
      isSuperDuperAdmin
        ? {
            label: 'Clear cache',
            key: 'settingsClearCache',
          }
        : null,
    ].filter(Boolean),
  };

  const userMenu = {
    selectable: true,
    selectedKeys: getSelectedReportKey(),
    className: 'navbar__submenu',
    onClick: handleMenuClick,
    items: [
      {
        label: (
          <Flex className="" gap="middle" align="center">
            <UserAvatar name={user.fullName} />
            <div>
              <div className="color-text text-bold">{user.fullName}</div>
              <div className="color-text-secondary">{user.email}</div>
            </div>
          </Flex>
        ),
        type: 'group',
        key: 'faUser',
      },
      { type: 'divider' },
      {
        label: (
          <>
            <Link to="/user-account">
              <UserOutlined className="pr-4" /> Manage account
            </Link>
          </>
        ),
        key: 'user-account',
      },
      isSuperAdmin ? { type: 'divider' } : null,
      isSuperAdmin
        ? {
            label: (
              <Link to="/internal/organizations">
                <UnorderedListOutlined className="pr-4" /> Organizations
              </Link>
            ),
            key: 'internal/organizations',
          }
        : null,
      user.isSuperAdmin
        ? {
            label: (
              <Link to="/internal/control-board-cs">
                <DashboardOutlined className="pr-4" /> CS Dashboard
              </Link>
            ),
            key: 'internal/control-board-cs',
          }
        : null,
      { type: 'divider' },
      {
        label: (
          <>
            <LogoutOutlined className="pr-4" /> Logout
          </>
        ),
        key: 'userLogout',
      },
    ].filter(Boolean),
  };

  const reportMenu = {
    selectable: true,
    selectedKeys: getSelectedReportKey(),
    className: 'navbar__submenu',
    items: [
      !hasOnlyDeiSurveys
        ? {
            label: <Link to="/reports/discussion-materials/">Discussion materials</Link>,
            key: 'discussion-materials',
          }
        : null,
      isAdmin
        ? { label: <Link to="/reports/portfolio">Portfolio view</Link>, key: 'reports/portfolio' }
        : null,
      has360View
        ? {
            label: <Link to="/reports/teamViews">360 team view</Link>,
            key: 'teamViews',
          }
        : null,
      isAdmin && hasDeiSurveys
        ? { label: <Link to="/reports/dei-reports">DEI Reports</Link>, key: 'reports/dei-reports' }
        : null,
    ].filter(Boolean),
  };

  const vcpMenu = {
    selectable: true,
    selectedKeys: getSelectedVCPKey(),
    className: 'navbar__submenu',
    items: [
      {
        label: <Link to="/vcp/plan/">Plan</Link>,
        key: 'plan',
      },
    ],
  };

  const showAlert =
    API_URL === 'https://live.entromy.com/api' && window.location.origin.includes('localhost');

  const homeUrl = !user.hasSurveys && isAdmin ? 'getting-started' : 'dashboard';

  return (
    <>
      <div className="navbar">
        <div className="navbar__content">
          <Row justify="space-between" align="middle">
            <Col flex="1">
              <img className="navbar__logo" src={logo.img || logoDefault} alt="logo" />
            </Col>
            {showAlert && (
              <Col flex="300px">
                <Alert message="You connected to live, be careful" type="error" showIcon />
              </Col>
            )}
            {organizations.length > 1 && !isManagerReviewPage() && (
              <Col>
                <Select
                  style={{ minWidth: '100px' }}
                  value={organization.id}
                  popupMatchSelectWidth={200}
                  showSearch={organizations.length > 10}
                  optionFilterProp="children"
                  onChange={switchOrg}
                  className="mr-16"
                >
                  {organizations
                    .filter((item) => (isSuperDuperAdmin ? true : item.demo !== 2))
                    .map((org) => {
                      const combinedName = `${org.orgname}${
                        org.internalName ? ` (${org.internalName})` : ''
                      }`;
                      const displayName =
                        combinedName.length > 50 ? `${combinedName.slice(0, 47)}...` : combinedName;

                      return (
                        <Option value={org.id} key={org.id}>
                          {displayName}
                        </Option>
                      );
                    })}
                </Select>
              </Col>
            )}
            <Col flex="1">
              <Row gutter={[16, 0]} align="middle" justify="end" wrap={false}>
                {isAdmin && (
                  <Col>
                    <Link
                      to={`/${homeUrl}`}
                      className={classNames('navbar__menu__item', {
                        'navbar__menu__item--active': isActive(homeUrl),
                      })}
                    >
                      <Icon icon={faHome} />
                      <div>Home</div>
                    </Link>
                  </Col>
                )}
                {isDiscussionMaterialRole && !isManagerReviewPage() && user?.hasManagerReviews && (
                  <Col>
                    <Link
                      to="/manager-reviews"
                      className={classNames('navbar__menu__item', {
                        'navbar__menu__item--active': isActive('manager-reviews'),
                      })}
                    >
                      <Icon icon={faHome} />
                      <div>Home</div>
                    </Link>
                  </Col>
                )}
                {isAdmin && <ManageSurveys setIsManageModalActive={setIsManageModalActive} />}
                {reportMenu.items.length ? (
                  <Col>
                    <Dropdown menu={reportMenu} placement="bottomRight">
                      <Button
                        type="text"
                        className={classNames('navbar__menu__item', {
                          'navbar__menu__item--active': isActive('reports'),
                        })}
                      >
                        <Icon icon={faChartLine} />
                        <div>Reports</div>
                      </Button>
                    </Dropdown>
                  </Col>
                ) : (
                  <>
                    {!isManagerReviewPage() && (
                      <Col>
                        <Link
                          to={
                            user.hasAccessToDeiSurveys
                              ? '/reports/dei-dashboard'
                              : '/reports/discussion-materials/'
                          }
                          className={classNames('navbar__menu__item', {
                            'navbar__menu__item--active': isActive('discussion-materials'),
                          })}
                        >
                          <Icon icon={faChartLine} />
                          <div>Reports</div>
                        </Link>
                      </Col>
                    )}
                  </>
                )}
                {isAdmin && (
                  <Col>
                    <Dropdown menu={vcpMenu} placement="bottomRight">
                      <Button
                        type="text"
                        className={classNames('navbar__menu__item', {
                          'navbar__menu__item--active': isActive('vcp'),
                        })}
                      >
                        <Icon icon={faUserNinja} />
                        <div>VCP</div>
                      </Button>
                    </Dropdown>
                  </Col>
                )}
                {isAdmin && (
                  <Col>
                    <Link
                      to="/review"
                      className={classNames('navbar__menu__item', {
                        'navbar__menu__item--active': isActive('review'),
                      })}
                    >
                      <Icon icon={faSearch} />
                      <div>Review</div>
                    </Link>
                  </Col>
                )}
                {isAdmin && !!settingsMenu.items.length && (
                  <Col>
                    <Dropdown menu={settingsMenu}>
                      <Button
                        type="text"
                        className={classNames('navbar__menu__item', {
                          'navbar__menu__item--active':
                            isActive('configure') || isActive('settings'),
                        })}
                      >
                        <Icon icon={faWrench} />
                        <div>Configure</div>
                      </Button>
                    </Dropdown>
                  </Col>
                )}
                {!isManagerReviewPage() && (
                  <Col>
                    <a
                      href="https://help.entromy.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="navbar__menu__item"
                    >
                      <Icon icon={faQuestionCircle} />
                      <div>Help</div>
                    </a>
                  </Col>
                )}
                {user.userId && !isManagerReviewPage() && (
                  <Col>
                    <Dropdown menu={userMenu}>
                      <Button type="text" className="pl-12 navbar__menu__item">
                        <UserAvatar
                          name={user.fullName}
                          className={{
                            active:
                              isActive('user-account') ||
                              isActive('internal/organizations') ||
                              isActive('internal/control-board-cs'),
                          }}
                        />
                        <DownOutlined className="ml-3" />
                      </Button>
                    </Dropdown>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Navbar;
